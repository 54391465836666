import { Routes } from '@angular/router';
import { LoginRouteGuardService } from './services/_core/login-route-guard/login-route-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/patients/all',
    pathMatch: 'full',
  },
  {
    path: 'login',
    pathMatch: 'full',
    loadComponent: () => import('./pages/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'redirect',
    loadComponent: () => import('./pages/redirect/redirect.page').then( m => m.RedirectPage)
  },
  {
    path: 'help',
    loadComponent: () => import('./pages/_core/help/help.page').then( m => m.HelpPage)
  },
  {
    path: 'share',
    loadComponent: () => import('./pages/_core/share/share.page').then( m => m.SharePage)
  },
  {
    path: 'check-for-updates',
    loadComponent: () => import('./pages/_core/check-for-updates/check-for-updates.page').then( m => m.CheckForUpdatesPage)
  },
  {
    path: 'help-forgot-pwd',
    loadComponent: () => import('./pages/_core/help-forgot-pwd/help-forgot-pwd.page').then( m => m.HelpForgotPwdPage)
  },
  {
    path: 'feedback-modal',
    loadComponent: () => import('./pages/_core/feedback-modal/feedback-modal.page').then( m => m.FeedbackModalPage)
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService],
    loadComponent: () => import('./pages/_core/my-account/my-account.page').then( m => m.MyAccountPage)
  },
  {
    path: 'language-modal',
    loadComponent: () => import('./pages/_core/language-modal/language-modal.page').then( m => m.LanguageModalPage)
  },
  {
    path: 'language-popover',
    loadComponent: () => import('./pages/_core/language-popover/language-popover.page').then( m => m.LanguagePopoverPage)
  },
  {
    path: 'language-translator',
    loadComponent: () => import('./pages/_core/language-translator/language-translator.page').then( m => m.LanguageTranslatorPage)
  },
  {
    path: 'patients/:view',
    canActivate: [LoginRouteGuardService],
    data: { pageName: 'Patient View', roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SYS_ADMIN'] },
    loadComponent: () => import('./pages/patient-list/patient-list.page').then( m => m.PatientListPage)
  },
  {
    path: 'patient-view',
    canActivate: [LoginRouteGuardService],
    data: { pageName: 'Patient View', roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SYS_ADMIN'] },
    loadComponent: () => import('./pages/patient-view/patient-view.page').then( m => m.PatientViewPage)
  },
  {
    path: 'manage',
    canActivate: [LoginRouteGuardService],
    data: { pageName: 'Patient View', roles: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN'] },
    loadComponent: () => import('./pages/manage/manage.page').then( m => m.ManagePage)
  },
  {
    path: 'manage/responses/:responseType',
    canActivate: [LoginRouteGuardService],
    data: { pageName: 'Patient View', roles: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN'] },
    loadComponent: () => import('./pages/manage-responses/manage-responses.page').then( m => m.ManageResponsesPage)
  },
  {
    path: 'manage/locations',
    canActivate: [LoginRouteGuardService],
    data: { pageName: 'Patient View', roles: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN'] },
    loadComponent: () => import('./pages/manage-locations/manage-locations.page').then( m => m.ManageLocationsPage)
  },
  {
    path: 'manage/surgeons',
    canActivate: [LoginRouteGuardService],
    data: { pageName: 'Manage Surgeons', roles: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN'] },
    loadComponent: () => import('./pages/manage-surgeons/manage-surgeons.page').then( m => m.ManageSurgeonsPage)
  },
  {
    path: 'manage/users',
    canActivate: [LoginRouteGuardService],
    data: { pageName: 'Patient View', roles: ['ROLE_SYS_ADMIN'] },
    loadComponent: () => import('./pages/manage-users/manage-users.page').then( m => m.ManageUsersPage)
  },
  {
    path: 'manage-responses-editor',
    loadComponent: () => import('./pages/manage-responses-editor/manage-responses-editor.page').then( m => m.ManageResponsesEditorPage)
  },
  {
    path: 'manage-locations-editor',
    loadComponent: () => import('./pages/manage-locations-editor/manage-locations-editor.page').then( m => m.ManageLocationsEditorPage)
  },
  {
    path: 'manage-users-editor',
    loadComponent: () => import('./pages/manage-users-editor/manage-users-editor.page').then( m => m.ManageUsersEditorPage)
  },
  {
    path: 'patient-field-history',
    loadComponent: () => import('./pages/patient-field-history/patient-field-history.page').then( m => m.PatientFieldHistoryPage)
  },
  {
    path: 'manage-surgeons',
    loadComponent: () => import('./pages/manage-surgeons/manage-surgeons.page').then( m => m.ManageSurgeonsPage)
  },
  {
    path: 'manage-surgeons-editor',
    loadComponent: () => import('./pages/manage-surgeons-editor/manage-surgeons-editor.page').then( m => m.ManageSurgeonsEditorPage)
  },
];
