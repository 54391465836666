import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { HelperUtilitiesService } from '../services/_core/helper-utilities/helper-utilities.service';

/**
 * ID: bh-format-timestamp-pipe
 * Name: Format Timestamp Pipe
 * Type: Pipe
 * Description: Formats timestamp string
 * Implementation:
 *    1.) Import pipes.module.ts (PipesModule) into page or component's module.ts
 *    2.) In HTML template, display desired variable with pipe reference:  {{ eventDateIso | formatDate: 'YYYY-MM-DDTHH:mm:ss.zzz' }}
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-13 - MW - v1: Initial dev
 */
@Pipe({
  name: 'formatLabel',
})
export class FormatLabelPipe implements PipeTransform {
  constructor(private helpers: HelperUtilitiesService) {}
  transform(value: string): string {
    if (!value) return value;

    // Regular expression to match the name format
    // ([\w]+) Matches one or more word characters
    // [\w]+(?: [A-Z])? Ensures match on word characters, but group instide pattern is Non-capturing group
    const regex = /^([\w]+, [\w]+(?: [A-Z])?)|([\w]+ [\w]+(?: [A-Z]?)?)/;

    const match = value.match(regex);
    //console.log('match', match);
    return match ? match[0] : value;
  }
}
